




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";

import Notice from "@/views/Mine/Set/About/components/Notice.vue";
@Component({
  components: {
    Notice,
  },
})
export default class NoticePage extends Mixins(Mixin) {
  back(): void {
    this.$router.go(-1);
  }
}
